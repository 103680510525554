import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";

const ResumePage = () => {
  return (
    <Layout>
      <SEO title="Resume" />
      <h1>WIP</h1>
      <span>Resume Page</span>
      <p>
        <Link to="/">Go home</Link>
      </p>
    </Layout>
  );
};

export default ResumePage;
